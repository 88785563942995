import React, { useEffect, useState } from "react";
import Header from "./Header";
import BottomHeader from "./BottomHeader";


const Director = () => {
  const [data, setData] = useState()


  const getDirectorData = async () => {
    const response = await fetch(`${process.env.REACT_APP_DATABASE_URL}/director?fields=*.*`)
    const data = await response.json()
    setData(data.data)
  }

  useEffect(() => {
    getDirectorData()
  }, [])




  return (
    <>
      <Header />
      <BottomHeader data={{ name: "Director Message" }} />

      <div className="director">
        <div className="dir_img">
          <img src={data?.director_image?.data.full_url} alt=""/>
        </div>


        <div className="princi_right">
          <h4>Director Message</h4>
          {
            data?.map((item) => {
              return (
                <p>{item.director_message}</p>
              )
            })
          }

        </div>
      </div>
    </>
  );
};

export default Director;
