import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Header from "./Header";
import BottomHeader from "./BottomHeader";
import ImageViewer from "./ImageViewer";
import './Gallery.css';

const Gallery = () => {

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [mdShow, setMdShow] = useState(false);
  const [xmShow, setXmShow] = useState(false);

  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_DATABASE_URL}/carousel?fields=*.*`);
      const jsonData = await response.json();

      const imageUrls = await Promise.all(jsonData.data[0].carousel_images.map(async (item) => {
        const response = await fetch(`${process.env.REACT_APP_API_ASSETS}/${item.directus_files_id}`);
        const fileData = await response.json();
        return fileData.data.data;
      }));

      setData(imageUrls);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <>
      <Header />
      <BottomHeader data={{ name: "Gallery" }} />

      <div className="heading3">
        <h4>GALLERY</h4>
      </div>

      <div className="container3" data-aos="flip-right" data-aos-duration="2000">
        <ImageViewer />

        <div className="container3_right">
          {data.map((item, index) => (
            <div key={index} onClick={() => setSmShow(index)} className="me-2">
              <img
                style={{
                  height: "12rem",
                  width: "12rem",
                }}
                src={item.full_url}
                alt=""
              />
            </div>
          ))}
        </div>
      </div>

      <Modal
        size="lg"
        show={smShow !== false}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            <img
              style={{
                width: "100%",
                height: "auto",
              }}
              src={data[smShow]?.full_url}
              alt=""
            />
          </Modal.Title>
        </Modal.Header>
      </Modal>

      <ImageViewer />
    </>
  );
};

export default Gallery;
