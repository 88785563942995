//import React from 'react'
// import React, { useState, useCallback } from "react";
// import ImageViewer from "react-simple-image-viewer";
// import * as constants from "../contants";

const View = () => {
  // const { data } = useQuery(constants.Gallery);

  // console.log(data?.Gallery[0]?.Gallery_image, "Gallery-daaataaaaa");

  // const [currentImage, setCurrentImage] = useState(0);
  // const [isViewerOpen, setIsViewerOpen] = useState(false);
  // const images = [
  //   "./img1/g1.jpeg",
  //   "./img1/g2.jpeg",
  //   "./img1/g3.jpeg",
  //   "./img1/g4.jpeg",
  //   "./img1/g5.jpeg",
  //   "./img1/g6.jpeg",
  //   "./img1/g7.jpeg",
  //   "./img1/g8.jpeg",
  // ];

  // const openImageViewer = useCallback((index) => {
  //   setCurrentImage(index);
  //   setIsViewerOpen(true);
  // }, []);

  // const closeImageViewer = () => {
  //   setCurrentImage(0);
  //   setIsViewerOpen(false);
  // };
  return (
    <>




      {/* <div className="image-new">
        {data?.Gallery[0]?.Gallery_image.map((item, index) => (
          <img
            // src={src}
            src={`http://0.0.0.0:8055/assets/${item?.directus_files_id?.id}`}
            onClick={() => openImageViewer(index)}
            width="250"
            height="250"
            key={index}
            alt=""
          />
        ))}
        
        {isViewerOpen && (
          <ImageViewer
            src={images}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}
      </div> */}
    </>
  );
};

export default View;
