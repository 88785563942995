import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./styles.css";
import App from './App';
import reportWebVitals from './reportWebVitals';


// import ImageComponent from "./components/Gallary";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <React.StrictMode>
      <App />
    </React.StrictMode>


    {/* <div className="App">
      <h1>Gallery</h1>
      <ImageComponent />
    </div> */}

  </>

);

reportWebVitals();




