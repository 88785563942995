import React from "react";
import Carousel from "../components/Carousel";
import Container1 from "./Container1";
import Container2 from "./Container2";
import Container3 from "./Container3";
import Container6 from "./Container6";
import Header from "./Header";
// import Scrollheader from "./Scrollheader";

const Home = () => {
  return (
    <>
      {/* <Scrollheader/> */}
      <Header />
      <Carousel />
      <Container1 />

      <Container2 />
      <Container3 />
      {/* <Container4 /> */}
      {/* <Container5 /> */}
      <Container6 />
    </>
  );
};

export default Home;
