//import logo from './logo.svg';
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import Gallery from "./components/Gallery";
// import Gallary from "./components/Gallary";
import About from "./components/About";
import Director from "./components/Director";
import Principal from "./components/Principal";
import Facilities from "./components/Facilities";
import Admission from "./components/Admission";
import Management from "./components/Management";
import ScrollToTop from "react-scroll-to-top";

import Header from "./components/Header";


function App() {



  return (
    <>
      {/* <ClientContext.Provider value={client}> */}
      <div className="App">
        <BrowserRouter>
          {/* <Popup /> */}
          {/* <Header /> */}

          {/* <Header /> */}



          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="director" element={<Director />} />
            <Route path="principal" element={<Principal />} />
            <Route path="management" element={<Management />} />
            <Route path="admission" element={<Admission />} />
            <Route path="facility" element={<Facilities />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="gallery" element={<Gallery />} />
            {/* <Route path="gallary" element={<Gallary />} /> */}
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
      {/* </ClientContext.Provider> */}
    </>
  );
}

export default App;
