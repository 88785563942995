import React from "react";

const container2 = () => {
  return (
    <>
      
        <div className="container2">
          <div className="contain_1" data-aos="zoom-in-up" data-aos-duration="3000">
            <a href="/about">ABOUT US</a>
            <a href="/principal">PRINCIPAL DESK</a>
            <a href="/director">DIRCTOR DESK</a>

            <a href="/facility">FACILITIES</a>
            <a href="/contact">CONTACT US</a>
            <a href="/admission">ADMISSION PROCESSURE</a>

            <a href="/about">SCHOOL INFORMATION</a>
            <a href="/gallery">GALLERY</a>
            <a href="/gallery">AWARDS & ACHIEVEMENTS</a>
          </div>
        </div>
     
      <div className="fr"></div>
    </>
  );
};

export default container2;
