import React from "react";
import Header from "./Header";
import BottomHeader from "./BottomHeader";

const About = () => {
  return (
    <>
      <Header />
      <BottomHeader data={{ name: "About Us" }} />

      <div className="aboutus">
        <div className="about_left">
          <img src="" alt="" />
        </div>
        <div className="about_mid">
          <h5>About Us</h5>
          <p>
            Suman Saurabh School, Firdous Nagar, Bhopal, Madhya Pradesh 462001  is a Higher secondary school for both
            girls and boys managed by Suman Saurabh School group.
            Board. Our institution is recogniged by the Madhya Pradesh Board of
            education.
          </p>
          <p>
            The aim of the school is to give its students a sound moral
            education while devoting special attention to their spiritual,
            intellectual, social and physical development. Our educational
            institutions are open to students of all religions. We are strongly
            committed to the cause of justice, therefore we offer educational
            opportunities also to the weaker section of society. We see each
            student as a unique and precious gift to her family and society, a
            person created by God to know and love Him and to work for Him by
            making this world a better place to live in. Parents are the first
            and most important educators to their children. School education can
            only be built on the foundation laid by the family.
          </p>
          <p>SPECIAL EFFORT ARE MADE AT Suman Saurabh School </p>
          <p>
            To help students to become mature, reliable people of character.
          </p>
          <p>To be clear and firm on principles and courageous in action.</p>
          <p>To value and use their freedom judiciously.</p>
          <p>Strive after excellence in every field.</p>
          <p>
            To be unselfish in the service of their fellow human beings and
            appreciate all religions.
          </p>
        </div>
        <div className="about_right">
          <img src="" alt="" />
        </div>
      </div>
    </>
  );
};

export default About;
