// import Carousel from "react-bootstrap/Carousel";
// import * as constants from "../contants";
import React, { useEffect, useState } from "react";
import Carousel from 'react-bootstrap/Carousel'
function CarouselFadeExample() {

  const [data, setData ] = useState([]) 


  const fetchData = async () => {

    try {
      const response = await fetch(`${process.env.REACT_APP_DATABASE_URL}/carousel?fields=*.*`);
      const jsonData = await response.json();
  
      const imageUrls = await Promise.all(jsonData.data[0].carousel_images.map(async (item) => {
        const response = await fetch(`${process.env.REACT_APP_API_ASSETS}/${item.directus_files_id}`);
        const fileData = await response.json();
        return fileData.data.data;
      }));
  
      setData(imageUrls);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  



  useEffect(()=>{
    fetchData()
  },[])

  return (
    <>
     <div className='baaner' style={{ marginTop: "0rem" }}>
      <Carousel>
        {data.map((item) => (
          <Carousel.Item interval={2000} key={item.id}>
            <img className="d-block w-100" src={item.full_url} alt="" />
          </Carousel.Item>
        ))}
      </Carousel>
      </div>
    </>
  );
}

export default CarouselFadeExample;
