import React from "react";
import { FaMapMarkerAlt, FaHeart } from "react-icons/fa";
import { BsTelephoneFill } from "react-icons/bs";
import { RiFacebookFill, RiTwitterFill, RiYoutubeFill } from "react-icons/ri";


const Footer = () => {
  return (
    <>
      <div className="footer1">
        <div className="f1" data-aos="flip-right" data-aos-duration="3000">
          <div className="fleft">
            <img src="" alt="" />
          </div>
          <div className="fright">
            <h4 style={{ marginBottom: "20px" }}>Suman Saurabh School  </h4>
            <div className="fx">
              <FaMapMarkerAlt
                style={{
                  color: "#b02c2b",
                  marginRight: "1.5rem",
                  fontSize: "1.5rem",
                }}
              />
              <p style={{ marginBottom: "20px", fontWeight: "500" }}>
                Firdous Nagar, Bhopal, Madhya Pradesh 462001
              </p>
            </div>

            <div className="fy">
              <BsTelephoneFill className="tele" />
              <p style={{ marginBottom: "20px", fontWeight: "500" }}>
                0755 273 3335
              </p>
            </div>
            <div className="fz">
              <a href="" target="blank">
                <RiFacebookFill
                  style={{
                    fontSize: "2rem",
                    border: "2px solid black",
                    borderRadius: "50%",
                    padding: "5px",
                    margin: "10px",
                  }}
                />
              </a>

              <a href="....">
                <RiTwitterFill
                  style={{
                    fontSize: "2rem",
                    border: "2px solid black",
                    borderRadius: "50%",
                    padding: "5px",
                    margin: "10px",
                  }}
                />
              </a>
              <a href="..." target="_blank">
                <RiYoutubeFill
                  style={{
                    fontSize: "2rem",
                    border: "2px solid black",
                    borderRadius: "50%",
                    padding: "5px",
                    margin: "10px",
                  }}
                />
              </a>
            </div>
          </div>
        </div>



        {/* <div class="mapouter"><div class="gmap_canvas"><iframe width="100%" height="100%" id="gmap_canvas" src="https://maps.google.com/maps?q=Firdous Nagar, Bhopal, Madhya Pradesh 462001&t=&z=10&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://2yu.co">2yu</a><br><style>.mapouter{position:relative;text-align:right;height:100%;width:100%;}</style><a href="https://embedgooglemap.2yu.co/">html embed google map</a><style>.gmap_canvas {overflow:hidden;background:none!important;height:100%;width:100%;}</style></div></div> */}




        <div className="f2" data-aos="flip-right" data-aos-duration="3000" style={{ marginTop: "2rem" }}>
          <iframe
            src="https://maps.google.com/maps?q=Firdous Nagar, Bhopal, Madhya Pradesh 462001&t=&z=10&ie=UTF8&iwloc=&output=embed" alt="...."
            style={{ width: "100%", height: "80%", border: "0" }}
            loading="lazy"
          ></iframe>
        </div>
      </div>
      <div className="footer2">
        <div className="fa">
          <a href="/gallery">GALLERY</a>
          <a href="/about">SCHOOL INFORMATION</a>
        </div>
        <div className="fa">
          <a href="/admission">NEWS</a>
          <a href="/principal">PRINCIPAL'S DESK</a>
        </div>
        <div className="fa">
          <a href="/admission">ADMISSION</a>

          <a href="/director">DIRECTOR DESK</a>
        </div>
        <div className="fa">
          <a href="/admission">ACADEMICS</a>

          <a href="/facility">FACILITY</a>
        </div>
        {/* <div className="fa fb">
          <p>
            <b>Copyright © 2023</b><br/>
            <span style={{ fontSize: "13px" }}>
              All Rights Reserved - www.bluebellsschool.in
            </span>
          </p>
        </div> */}
      </div>
      <div className="footer3">
        <div className="policy" id="footer-policy">
          <div className="policy-row">
            <div className="policy-col">
              <p>
                Copyright © 2023 All Rights Reserved - www.sumansaurabhschool.in
              </p>
            </div>
            <div className="policy-col">
              <p>
                Design with{" "}
                <span>
                  <FaHeart style={{ color: "red" }} />
                </span>{" "}
                by <a href="https://maitretech.com/">maitretech.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Footer;
