import { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
// import * as constants from "../contants";

const Container6 = () => {
  const [value, onChange] = useState(new Date());

  // const { data } = useQuery(constants.Topper_image);
  // const { data: NoticeData } = useQuery(constants.Notice);

  // console.log(data?.Topper_image[0].Topper_image, "Topper_image")
  //console.log(NoticeData?.Notice[0].Notice3_date, "datas_name");
  // console.log(NoticeData?.Notice[0].Notice1_info, "datas_name")
  // console.log(datas?.Notice[0].Notice1_info[0], "datas_name6154165326532")
  // console.log(datas?.Notice[0].Notice2_date[1], "datas_name")
  // console.log(datas?.Notice[0].Notice2_date[1], "datas_name")
  // console.log(datas?.Notice[0].Notice3_date[2], "datas_name")
  // console.log(datas?.Notice[0].Notice3_date[2], "datas_name")
  // console.log(datas?.Notice[0].Notice4_date[3], "datas_name")
  // console.log(datas?.Notice[0].Notice4_date[3], "datas_name")
  return (
    <>
      <div className="container6">

        <div className="contain6">
          <div className="card6" data-aos="flip-right" data-aos-duration="3000">
            <p>SCHOOL TOPPER</p>
            <img style={{ height: "218px" }}
              src="./img/plac.jpg" alt=""
            />
            <p>vikash</p>

          </div>
          <div className="card6" data-aos="flip-right" data-aos-duration="3000">
            <p>SCHOOL CALENDER</p>
            <div className="calender">
              <Calendar onChange={onChange} value={value} />
            </div>
          </div>
          <div className="card6" data-aos="flip-right" data-aos-duration="1000">
            <p>NOTICE</p>
            <div className="item_event">
              <marquee width="100%" direction="up" height="265px">
                <div className="news" style={{ overflow: "hidden" }}>
                  <ul className="d-flex">
                    <li className="news11 text-light"
                      style={{ backgroundColor: "rgb(21, 21, 90)", listStyle: "none", height: "6rem", width: "10rem", marginLeft: "-30px" }}>
                      2021-10-20</li>
                    <li className="news12"
                      style={{
                        backgroundColor: "rgb(240, 219, 237)", listStyle: "none", height: "6rem", width: "20rem",
                        overflow: "hidden"
                      }}>
                      EDUCATIONAL
                      FACILITIES & STUDENT SAFETY</li>
                  </ul>
                  <ul className="d-flex">
                    <li className="news11 text-light"
                      style={{ backgroundColor: "rgb(21, 21, 90)", listStyle: "none", height: "6rem", width: "10rem", marginLeft: "-30px" }}>
                      2020-06-18</li>
                    <li className="news12"
                      style={{
                        backgroundColor: "rgb(240, 219, 237)", listStyle: "none", height: "6rem", width: "20rem",
                        overflow: "hidden"
                      }}>
                      NOTICE
                      FOR
                      CLASS VII 2018-19</li>
                  </ul>
                  <ul className="d-flex">
                    <li className="news11 text-light"
                      style={{ backgroundColor: "rgb(21, 21, 90)", listStyle: "none", height: "6rem", width: "10rem", marginLeft: "-30px" }}>
                      2020-10-05</li>
                    <li className="news12"
                      style={{
                        backgroundColor: "rgb(240, 219, 237)", listStyle: "none", height: "6rem", width: "20rem",
                        overflow: "hidden"
                      }}>
                      HIGHEST-SCORER-CLASS XII</li>
                  </ul>
                  <ul className="d-flex">
                    <li className="news11 text-light"
                      style={{ backgroundColor: "rgb(21, 21, 90)", listStyle: "none", height: "6rem", width: "10rem", marginLeft: "-30px" }}>
                      2020-10-07</li>
                    <li className="news12"
                      style={{
                        backgroundColor: "rgb(240, 219, 237)", listStyle: "none", height: "6rem", width: "20rem",
                        overflow: "hidden"
                      }}>
                      EDUCATIONAL
                      FACILITIES & STUDENT SAFETY</li>
                  </ul>
                  <ul className="d-flex">
                    <li className="news11 text-light"
                      style={{ backgroundColor: "rgb(21, 21, 90)", listStyle: "none", height: "6rem", width: "10rem", marginLeft: "-30px" }}>
                      2020-10-28</li>
                    <li className="news12"
                      style={{
                        backgroundColor: "rgb(240, 219, 237)", listStyle: "none", height: "6rem", width: "20rem",
                        overflow: "hidden"
                      }}>
                      SPECIAL
                      STRATEGIES FOR GIRLS & MOTIVATIONAL PLANS</li>
                  </ul>
                </div>
              </marquee>

            </div>
            {/* <div className="item_event">
              <marquee
                direction="up"
                scrollamount="4"
                behaviour="up"
                width="100%"
                height="305px"
              >

              </marquee>

              <marquee width="250rem" direction="up" height="280rem" >
                <div className="news" style={{ overflow: "hidden" }}>
                  <ul className="d-flex">
                    <li className="news11 text-light"
                      style={{ backgroundColor: "rgb(21, 21, 90)", listStyle: "none", height: "6rem", width: "10rem" }}>
                      2021-10-20</li>
                    <li className="news12"
                      style={{
                        backgroundColor: "rgb(240, 219, 237)", listStyle: "none", height: "6rem", width: "20rem",
                        overflow: "hidden"
                      }}>
                      EDUCATIONAL
                      FACILITIES & STUDENT SAFETY</li>
                  </ul>
                  <ul className="d-flex">
                    <li className="news11 text-light"
                      style={{ backgroundColor: "rgb(21, 21, 90)", listStyle: "none", height: "6rem", width: "10rem" }}>
                      2020-06-18</li>
                    <li className="news12"
                      style={{
                        backgroundColor: "rgb(240, 219, 237)", listStyle: "none", height: "6rem", width: "20rem",
                        overflow: "hidden"
                      }}>
                      NOTICE
                      FOR
                      CLASS VII 2018-19</li>
                  </ul>
                  <ul className="d-flex">
                    <li className="news11 text-light"
                      style={{ backgroundColor: "rgb(21, 21, 90)", listStyle: "none", height: "6rem", width: "10rem" }}>
                      2020-10-05</li>
                    <li className="news12"
                      style={{
                        backgroundColor: "rgb(240, 219, 237)", listStyle: "none", height: "6rem", width: "20rem",
                        overflow: "hidden"
                      }}>
                      HIGHEST-SCORER-CLASS XII</li>
                  </ul>
                  <ul className="d-flex">
                    <li className="news11 text-light"
                      style={{ backgroundColor: "rgb(21, 21, 90)", listStyle: "none", height: "6rem", width: "10rem" }}>
                      2020-10-07</li>
                    <li className="news12"
                      style={{
                        backgroundColor: "rgb(240, 219, 237)", listStyle: "none", height: "6rem", width: "20rem",
                        overflow: "hidden"
                      }}>
                      EDUCATIONAL
                      FACILITIES & STUDENT SAFETY</li>
                  </ul>
                  <ul className="d-flex">
                    <li className="news11 text-light"
                      style={{ backgroundColor: "rgb(21, 21, 90)", listStyle: "none", height: "6rem", width: "10rem" }}>
                      2020-10-28</li>
                    <li className="news12"
                      style={{
                        backgroundColor: "rgb(240, 219, 237)", listStyle: "none", height: "6rem", width: "20rem",
                        overflow: "hidden"
                      }}>
                      SPECIAL
                      STRATEGIES FOR GIRLS & MOTIVATIONAL PLANS</li>
                  </ul>
                </div>
              </marquee>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Container6;
