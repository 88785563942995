import React, { useState, useRef, useLayoutEffect } from "react";
import { NavLink } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";

// import Carousel from "../components/Carousel";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [down, setDown] = useState(false);

  // const stickyHeader = useRef();
  // useLayoutEffect(() => {
  //   const navbar = document.getElementById("navbar");
  //   let fixedTop = stickyHeader.current.offsetTop;
  //   const fixedHeader = () => {
  //     if (window.pageYOffset > fixedTop) {
  //       navbar.classList.add("fixedTop");
  //     } else {
  //       navbar.classList.remove("fixedTop");
  //     }
  //   };
  //   window.addEventListener("scroll", fixedHeader);
  // }, []);


  return (
    <>
      {/* <div className="top">
       
        <div className="right">
          <div className="first">
            <a href="mailto:sumansaurabhschool@gmail.com">
              sumansaurabhschool@gmail.com
            </a>
            <br />
            <a href="tel: 0755 273 3335 ">
              0755 273 3335
            </a>
          </div>

        </div>
      </div> */}








      <div className="navbar" id="navbar">
      
        <div className="navleft">
          <img src="../img/Logo_Suman.png" />
          <img src="" alt="" />
        </div>
        <div className="navright">
          <ul>
            <li>
              <NavLink to="/" className="new">HOME</NavLink>
            </li>
            <li className="new">
              <NavLink>ABOUT US</NavLink>
              <ul className="abouts">
                <li>
                  <NavLink to="/about" className="abouts1">
                    ABOUT US
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/director" className="abouts1">
                    DIRECTOR MESSAGE
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink to="/management" className="abouts1">
                    MANAGEMENT MESSAGE
                  </NavLink>
                </li> */}
                <li>
                  <NavLink to="/principal" className="abouts1">
                    PRINCIPAL MESSAGE
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="new">
              <NavLink>ACADEMICS</NavLink>
              <ul className="abouts">
                <li>
                  <NavLink to="/admission" className="abouts1">
                    ADMISSION PROCESSURE
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/facility" className="abouts1">
                    FACILITIES
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to="/gallery" className="new">
                GALLERY
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact" className="new">
                CONTACT US
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                to="https://cms.schoolscoop.co.in/admin/#/login"
                target="_blank"
                className="new"
              >
                LOGIN
              </NavLink>
            </li> */}
            {/* <li>
              <NavLink className="new">HALL OF FAME</NavLink>
            </li> */}
          </ul>
        </div>
      </div>

      {/* <div className="nav_scrolled">
        <div className="nav_left">
          <img src="./img/logo.png" alt="" />
        </div>
        <div className="nav_right">
          <ul>
            <li>
              <NavLink className="new">HOME</NavLink>
            </li>
            <li>
              <NavLink className="new">ABOUT US</NavLink>
            </li>
            <li>
              <NavLink className="new">ACADEMICS</NavLink>
            </li>
            <li>
              <NavLink className="new">GALLERY</NavLink>
            </li>
            <li>
              <NavLink className="new">CONTACT US</NavLink>
            </li>
            <li>
              <NavLink className="new">LOGIN</NavLink>
            </li>
            <li>
              <NavLink className="new">HALL OF FAME</NavLink>
            </li>
          </ul>
        </div>
      </div> */}

       <div className="mobile">

        {/*<div className="mobile_top">
          <ul>
            <li>
              <a href="mailto:shriramhss1978@gmail.com">
                shriramhss1978@gmail.com
              </a>
            </li>

            <li>
            <a href="tel:  8962523839, 8358836350 ,  9424485443">
                8962523839, 8358836350 ,  9424485443
              </a>
            </li>
          </ul>

        </div> */}

        <div className="mobile_top2">
        <img src="../img/Logo_Suman.png" style={{height : "10vh", width : "auto"}}/>
          <img src="" alt="" />
          <div className="mobile_right" >
            <FaBars
              style={{ color: "#ffff", fontSize: "2rem" }}
              onClick={() => setOpen(!open)}
            />

            {open ? (
              <div className="mob">
                <ul>
                  <li>
                    <NavLink to="/">HOME</NavLink>
                  </li>
                  <li className="new-1">
                    <NavLink>
                      ABOUT US{" "}
                      <IoMdArrowDropdown onClick={() => setShow(!show)} />
                    </NavLink>
                    {show ? (
                      <ul className="new1" onClick={() => setShow(!show)}>
                        <li className="drop" onClick={() => setShow(!show)}>
                          <NavLink to="/about">ABOUT US</NavLink>
                        </li>
                        <li className="drop" onClick={() => setShow(!show)}>
                          <NavLink to="/director">DIRECTOR MESSAGE</NavLink>
                        </li>

                        <li className="drop" onClick={() => setShow(!show)}>
                          <NavLink to="/principal">PRINCIPAL MESSAGE</NavLink>
                        </li>
                      </ul>
                    ) : (
                      ""
                    )}
                  </li>
                  <li className="new-1">
                    <NavLink>
                      ACADEMICS{" "}
                      <IoMdArrowDropdown onClick={() => setDown(!down)} />
                    </NavLink>
                    {down ? (
                      <ul className="new1" onClick={() => setDown(!down)}>
                        <li onClick={() => setShow(!show)}>
                          <NavLink to="/admission">
                            ADMISSION PROCESSURE
                          </NavLink>
                        </li>
                        <li onClick={() => setShow(!show)}>
                          <NavLink to="/facility">FACILITIES</NavLink>
                        </li>
                      </ul>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    <NavLink to="/gallery">GALLERY</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact">CONTACT</NavLink>
                  </li>

                </ul>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>


        {/* <div class="menu-bar">
          <ul>
            <li>
              <NavLink to="/">HOME</NavLink>
            </li>

            <li>
              <NavLink to="/about">
                ABOUT US
              </NavLink>

            </li>

            <li>
              <NavLink to="/our_department">OUR DEPARTMENTS</NavLink>
            </li>


            <li>
              <NavLink to="/facility">FACILITIES WE PROVIDE</NavLink>
            </li>






            <li>
              <NavLink to="/gallery">
                GALLERY
              </NavLink>


            </li>




            <li>
              <NavLink to="/contact">
                CONTACT US
              </NavLink>


            </li>



          </ul>
        </div> */}


      </div>

    </>
  );
};

export default Header;
