import React, { useEffect, useState } from "react";

const Container1 = () => {
  const[data, setdata]=useState()

  const fetchData= async()=>{
    const response =await fetch(`${process.env.REACT_APP_DATABASE_URL}/home_about_section?fields=*.*`)
    const data = await response.json()
    setdata(data.data[0])

  }
  useEffect(()=>{
    fetchData()
  },[])



  return (
    <>
      <div className="container1">
        <div
          className="contain1left"
          data-aos="flip-right"
          data-aos-duration="3000"
        >
          <h5> {data?.home_about_heading} </h5>
          <p style={{fontSize:"18px"}}>

            {data?.home_about_paragraph}
          </p>

        </div>

        <div
          className="contain1right"
          data-aos="flip-right"
          data-aos-duration="2000"
        >
          <img src={data?.home_about_image.data.full_url} alt="" />
        </div>
      </div>
    </>
  );
};

export default Container1;
