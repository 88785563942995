import React from "react";
import Header from "./Header";
import BottomHeader from "./BottomHeader";
const Contact = () => {
  return (
    <>
      <Header />

      
      <BottomHeader data={{ name: "Contact Us" }} />

      <div className="heading-con">
        <h2>Contact</h2>








        {/* <iframe
          className="i-map"
          src="https://maps.google.com/maps?width=700&amp;height=400&amp;hl=en&amp;q=Satyagyan%20Colony%2C%20Chhola%20Dusshera%20Maidan%2C%20Vidisha%20Road%20%2C%20Bhopal%20+(Shri%20Ram%20Higher%20Secondary%20School%20)&amp;ie=UTF8&amp;t=&amp;z=16&amp;iwloc=B&amp;output=embed"
          width="100%"
          height="400"
          style={{ border: "0" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe> */}
      </div>
      <div className="cont">
        <p>
          <b>School</b> - Suman Saurabh School
        </p>
        <p>
          <b>Address</b>-   Firdous Nagar, Bhopal, Madhya Pradesh 462001
        </p>
        <p>
          <b>Phone no.</b>-   0755 273 3335
        </p>
        <p>
          <b>Email</b>-  sumansaurabh@gmail.com
        </p>
      </div>
    </>
  );
};

export default Contact;
