import React , { useEffect, useState } from "react";
import ImageViewer from "./ImageViewer"

const Container3 = () => {

  const [data, setData ] = useState([]) 


  const fetchData = async () => {

    try {
      const response = await fetch(`${process.env.REACT_APP_DATABASE_URL}/gallery?fields=*.*`);
      const jsonData = await response.json();
      const imageUrls = await Promise.all(jsonData.data[0].gallery_images.map(async (item) => {
        const response = await fetch(`${process.env.REACT_APP_API_ASSETS}/${item.directus_files_id}`);
        const fileData = await response.json();
        return fileData.data.data;
      }));
  
      setData(imageUrls);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };



  useEffect(()=>{
    fetchData()
  },[])

  return (
    <>
      <div className="heading3">
        <h4>GALLERY</h4>
      </div>
      <div className="container3" data-aos="flip-right" data-aos-duration="2000">
        <ImageViewer />
        <div className="container3_right">
          {
            data.map((item)=>{
              return (<>
              <div className="home-images" id = "home-images">
            <img src={item?.full_url} alt=""  />
          </div>
              </>)
            })
          }
        </div>
      </div>
      <a
        href="/gallery"
        style={{
          display: "flex",
          justifyContent: "center",
          color: "#b02c2b",
          textDecoration: "none",
          fontSize: "1.2rem",
        }}
      >
        button
      </a>
    </>
  );
};

export default Container3;
