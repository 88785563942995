import React, { useEffect, useState } from "react";
import Header from "./Header";

import BottomHeader from "./BottomHeader";

const Principal = () => {
  const [data, setData] = useState()


  const getDirectorData = async () => {
    const response = await fetch(`${process.env.REACT_APP_DATABASE_URL}/principal?fields=*.*`)
    const data = await response.json()
    setData(data.data)
  }

  useEffect(() => {
    getDirectorData()
  }, [])







  return (
    <>
      <Header />
      <BottomHeader data={{ name: "Principal Message" }} />

      <div className="princi">
        <div className="princi_left">
          <div className="pri">
          <img src={data?.principal_image?.data.full_url} alt=""/>
            <h5></h5>
          </div>
        </div>
        <div className="princi_right">
          <h4>Principal Message</h4>

         
          {
            data?.map((item)=>{
              return(
                <>
                  <p>{item.principal_message}</p>
                </>
              )
            })
          }
        </div>
      </div>
    </>
  );
};

export default Principal;
